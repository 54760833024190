.link {
  display: flex;
  align-items: center;
  padding-inline: 15px;
  gap: 12px;
  padding-block: 4px;
  margin-block: 2px;
  color: #000;
  width: 100%;
  height: 2rem;
  width: 13rem;
  font-size: 18px;
  font-weight: 400;
}
.link:hover {
  /* color: #ffa500; */
  color: #adadad;
}
.activelink {
  display: flex;
  align-items: center;
  padding-inline: 15px;
  gap: 12px;
  padding-block: 4px;
  margin-block: 2px;
  color: #000;
  background-color: #adadad;
  width: 100%;
  color: #fff;
  height: 2rem;
  width: 13rem;
  font-size: 18px;
  font-weight: 400;
}
.select {
  flex: 1;
  display: flex;
  background-color: #000;
  width: 100%;
}

/* .css-xerlbm > div{
  width: 4rem !important;
} */

.Toastify__progress-bar--animated {
  animation: customTrackProgress linear 2s forwards !important;
}

@keyframes customTrackProgress {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

